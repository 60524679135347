import React  from 'react';
import styled from '@emotion/styled';

const Overlay = styled.div`
  position: relative;
  .int-text {
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 10%;
    right: 10%;
    font-size: 1.2rem;
    color: #fff;
    text-shadow: 0 0 15px rgba(0,0,0,1);
    p {
      margin-bottom: 10px;
      b {
        font-size: 1.2rem;
      }
    }
    b {
      font-size: 1.8rem;
    }
    @media (max-width: 760px) {
      b {
        font-size: 1.4rem;
      }
      font-size: 1.0rem;
      p {
        margin-bottom: 10px;
      };
    }
    @media (max-width: 540px) {
      b {
        font-size: 1.0rem;
      }
      font-size: 0.8rem;
    }
    @media (max-width: 540px) {
      b {
        font-size: 0.8rem;
      }
      font-size: 0.6rem;
    }
  }
`;

const ImageWithText = ({ children }) => {
  return (
    <Overlay>
      {children}
    </Overlay>
  )
};

export default ImageWithText;